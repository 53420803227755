@import '../../../assets/sass/utilities/variables';

.social-link {
  padding: 13px 0 9px;
  text-align: center;
  display: inline-block;
  width: 56px;
  height: 48px;
  color: $c-primary;
  border-radius: 6px;
  background-color: $c-secondary-80;
  &:hover {
    background-color: $c-secondary-50;
  }
  svg {
    width: 22px;
    height: auto;
  }
}
