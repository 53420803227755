@import '../../assets/sass/utilities/variables';
@import '../../assets/sass/utilities/mixins';

.footer {
  padding: 40px 0 80px;
  background-color: $c-primary-90;
  &__link,
  &__copy {
    display: block;
    color: $c-secondary-80;
    font-size: $f-small;
    line-height: 20px;
  }
  &__link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
