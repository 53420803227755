.app {
  width: 100%;
  overflow: hidden;
}

.bx {
  $self: &;
  &--form-item {
    margin-bottom: 15px;
    @include breakpoint($b-md) {
      flex-grow: unset;
      padding: 0 12px;
      width: 50%;
    }
  }
  &--label {
    margin-bottom: 7px;
    font-size: $f-small;
    color: $c-primary-50;
  }
  &--select {
    width: 100%;
    &__arrow {
      width: 24px;
    }
  }
  &--select-input {
    $select-input: &;
    height: 48px;
    width: 100%;
    max-width: 100%;
    color: $c-primary-90;
    font-size: $f-main;
    line-height: 48px;
    border-bottom: none;
    border-radius: 6px;
    outline: none;
    &:hover {
      background-color: $c-secondary-80;
    }
    &:focus {
      outline: none;
    }
    &__wrapper {
      width: 100%;
      &[data-invalid] {
        ~ #{$self}--form-requirement {
          color: $c-error;
        }
        #{$select-input}:not(:focus) {
          outline: none;
          border: 2px solid $c-error;
        }
        #{$select-input} ~ #{$self}--select__invalid-icon {
          fill: $c-error;
        }
      }
    }
  }
  &--text-input,
  &--text-area {
    $text-input: &;
    height: 48px;
    color: $c-primary-90;
    font-size: $f-main;
    line-height: 48px;
    border-bottom: none;
    border-radius: 6px;
    outline: none;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: $c-secondary-30;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $c-secondary-30;
    }
    &::-ms-input-placeholder {
      color: $c-secondary-30;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &__field-wrapper {
      &[data-invalid] {
        ~ #{$self}--form-requirement {
          color: $c-error;
        }
        > #{$text-input} {
          &--invalid:not(:focus) {
            outline: none;
            border: 2px solid $c-error;
          }
        }
        #{$text-input} {
          &__invalid-icon {
            fill: $c-error;
          }
        }
      }
    }
  }
  &--text-area {
    min-height: 48px;
    height: inherit;
    line-height: 21px;
  }
  &--btn {
    $btn: &;
    padding: 12px 15px;
    justify-content: center;
    min-width: 188px;
    font-size: $f-main;
    line-height: 20px;
    border-radius: 6px;
    &:focus {
      color: inherit;
      border: none;
      box-shadow: none;
    }
    &--primary {
      color: $c-secondary;
      background-color: $c-primary-90;
      &:hover,
      &:active,
      &:focus {
        color: $c-secondary;
        background-color: $c-primary-80;
      }
      &#{$btn}--dark {
        color: $c-secondary;
        background-color: $c-primary-80;
        &:hover,
        &:active,
        &:focus {
          background-color: $c-primary-50;
        }
      }
    }
    &--secondary {
      color: $c-primary-90;
      background-color: $c-secondary-80;
      &:hover,
      &:active,
      &:focus {
        color: $c-primary-90;
        background-color: $c-secondary-50;
      }
      &#{$btn}--white {
        color: $c-primary-90;
        background-color: $c-secondary;
        &:hover,
        &:active,
        &:focus {
          background-color: $c-secondary-80;
        }
      }
    }
    &--ghost {
      padding: 0;
      min-width: unset;
      min-height: auto;
      color: $c-error;
      background-color: transparent;
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
      }
    }
    &--hide {
      display: none;
    }
    &--show-md {
      @include breakpoint($b-md) {
        display: inline-flex;
      }
    }
    &--hide-md {
      @include breakpoint($b-md) {
        display: none;
      }
    }
    &--icon-only {
      &:focus {
        outline: none;
      }
    }
  }
  &--tab-content {
    padding: 25px 0 25px;
    @include breakpoint($b-md) {
      padding: 30px 0 30px;
    }
  }
  &--tabs {
    &--scrollable {
      $tabs: &;
      #{$tabs}__nav {
        width: 100%;
        &:focus {
          outline: none;
        }
      }
      #{$tabs}__nav-item {
        width: 50%;
        @include breakpoint($b-md) {
          margin-right: 55px;
          width: auto;
        }
        &:hover {
          #{$tabs}__nav-link {
            border: none;
            @include breakpoint($b-md) {
              border-bottom: 2px solid $c-primary;
            }
          }
        }
        &--selected {
          #{$tabs}__nav-link,
          #{$tabs}__nav-link:focus,
          #{$tabs}__nav-link:active {
            color: $c-primary;
            font-weight: 800;
            font-size: $f-large;
            line-height: 24px;
            border: none;
            @include breakpoint($b-md) {
              font-weight: 600;
              border-bottom: 2px solid $c-primary;
            }
          }
        }
      }
      #{$tabs}__nav-link {
        padding: 0;
        color: $c-secondary-50;
        outline: none;
        font-weight: 800;
        font-size: $f-large;
        line-height: 24px;
        border: none;
        @include breakpoint($b-md) {
          padding-bottom: 10px;
          width: auto;
          font-weight: 600;
        }
        &:focus,
        &:active {
          outline: none;
        }
      }
    }
  }
  &--accordion {
    $accordion: &;
    &__item {
      margin-bottom: 8px;
      overflow: hidden;
      border: none;
      border-radius: 6px;
      &:last-child {
        border: none;
      }
      &--active {
        #{$accordion}__content {
          padding: 12px 15px 20px;
          background-color: $c-secondary-80;
        }
        #{$accordion}__title {
          font-weight: 600;
        }
      }
    }
    &__heading {
      padding: 13px 0;
      overflow: hidden;
      background-color: $c-secondary-80;
      &:focus {
        &:before {
          border: none;
        }
      }
    }
    &__title {
      font-size: 16px;
      line-height: 26px;
    }
    &__arrow {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
    }
  }
  &--modal {
    #{$self}--text-input,
    #{$self}--text-area {
      background-color: $c-secondary-80;
    }
  }
  &--modal-container {
    background-color: $c-secondary;
    max-width: 835px;
    @include breakpoint($b-md) {
      border-radius: 10px;
    }
    #{$self}--modal-content {
      @include breakpoint($b-md) {
        padding: 10px 40px 20px 40px;
      }
    }
  }
  &--modal-header {
    padding: 40px 60px 0 15px;
    @include breakpoint($b-md) {
      padding: 40px 60px 0 40px;
    }
  }
  &--modal-close {
    margin: 39px 19px 0 0;
    padding: 0;
    width: auto;
    height: auto;
    border: none;
    @include breakpoint($b-md) {
      margin-right: 40px;
    }
    &__icon {
      width: 36px;
      height: 36px;
    }
  }
}

.auth__form,
.profile__form {
  #rate.bx--text-input {
    padding-right: 60px;
  }
  .bx {
    &--text-input {
      &__field-outer-wrapper {
        position: relative;
      }
    }
    &--form {
      &__helper-text {
        position: absolute;
        top: 0;
        right: 15px;
        z-index: 1;
        margin-top: 0;
        display: inline-block;
        width: auto;
        color: $c-primary;
        font-size: $f-small;
        line-height: 48px;
      }
    }
  }
}
