@import '../../assets/sass/utilities/variables';
@import '../../assets/sass/utilities/mixins';

.logo {
  width: 60px;
  height: 30px;
  @include breakpoint($b-md) {
    width: 137px;
  }
  &--footer {
    margin-bottom: 15px;
  }
  &__img {
    width: 100%;
    height: auto;
    display: none;
    @include breakpoint($b-md) {
      display: inline-block;
    }
    &--mob {
      display: inline-block;
      @include breakpoint($b-md) {
        display: none;
      }
    }
  }
}
