@import '../../assets/sass/utilities/variables';
@import '../../assets/sass/utilities/mixins';

.project-detail {
  padding-bottom: 80px;
  &__brief {
    margin-bottom: 30px;
    @include breakpoint($b-md) {
      padding-right: 10px;
      width: 45%;
    }
    @include breakpoint($b-lg) {
      padding-right: 20px;
      width: 360px;
    }
    > .c-text {
      margin-bottom: 45px;
    }
  }
  &__positions {
    @include breakpoint($b-md) {
      padding-left: 10px;
      width: 55%;
    }
    @include breakpoint($b-lg) {
      padding-left: 20px;
      width: calc(100% - 360px);
    }
  }
  &__assets {
    margin-bottom: 30px;
    @include breakpoint($b-md) {
      order: 1;
      padding-left: 10px;
      width: 55%;
    }
    @include breakpoint($b-lg) {
      padding-left: 20px;
      width: calc(100% - 360px);
    }
    .l-list {
      margin-bottom: 20px;
      @include breakpoint($b-lg) {
        margin: 0 -4px 20px;
      }
    }
    .l-list__item {
      margin-bottom: 8px;
      @include breakpoint($b-lg) {
        padding: 0 4px;
        width: 50%;
      }
    }
    .bx--btn {
      margin: 0 15px 10px 0;
    }
  }
  &__team {
    @include breakpoint($b-md) {
      padding-right: 10px;
      width: 45%;
    }
    @include breakpoint($b-lg) {
      padding-right: 20px;
      width: 360px;
    }
    .l-list__item {
      margin-bottom: 20px;
    }
    .bx--btn {
      margin-top: 10px;
    }
  }
  &__team-block {
    margin-bottom: 30px;
  }
  .bx--accordion {
    margin-bottom: 40px;
    .c-text {
      margin-bottom: 20px;
    }
  }
}

.c-hero {
  margin-bottom: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 300px;
  background-color: $c-secondary-30;
  background-position: center top;
  background-size: auto 100%;
  background-repeat: no-repeat;
  @include breakpoint($b-md) {
    padding-bottom: 50px;
    margin-bottom: 40px;
    height: 375px;
  }
  @include breakpoint(1200px) {
    background-position: center top;
    background-size: 100% auto;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .c-title {
    margin-bottom: 15px;
    color: $c-secondary;
    font-weight: 600;
    font-size: $f-biggest;
    line-height: 35px;
    @include breakpoint($b-md) {
      font-size: $f-huge;
      line-height: 40px;
    }
  }
  .c-text {
    color: $c-secondary;
  }
  &__info {
    max-width: 322px;
  }
}
