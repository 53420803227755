@import '../../assets/sass/utilities/variables';
@import '../../assets/sass/utilities/mixins';

.auth {
  $self: &;
  &__title {
    margin-bottom: 8px;
    color: $c-primary;
    font-size: $f-main;
    line-height: 28px;
    &--standalone {
      margin-bottom: 40px;
    }
  }
  &__text {
    color: $c-primary;
    font-size: $f-main;
    line-height: 28px;
    &--mb-1 {
      margin-bottom: 10px;
    }
  }
  &__steps {
    margin-bottom: 30px;
    display: inline-block;
    color: $c-secondary-30;
    font-size: $f-small;
    line-height: 28px;
  }
  &__link {
    margin: 10px 0;
    display: inline-block;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .bx--label {
    color: $c-primary-50;
  }
  &--dark {
    max-width: 755px;
    background-color: $c-primary-90;
    #{$self}__title {
      color: $c-secondary-80;
      font-size: $f-medium;
    }
    #{$self}__steps {
      color: $c-secondary-30;
    }
    .bx--label {
      color: $c-secondary-50;
    }
    .bx--btn {
      color: $c-secondary;
      background-color: $c-primary-80;
      &:hover,
      &:active,
      &:focus {
        background-color: $c-primary-50;
      }
    }
    .bx--text-input {
      color: $c-secondary;
      background-color: $c-primary-80;
      &::placeholder {
        color: $c-secondary-50;
      }
      &:-ms-input-placeholder {
        color: $c-secondary-50;
      }
      &::-ms-input-placeholder {
        color: $c-secondary-50;
      }
    }
  }
}
