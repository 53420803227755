@font-face {
  font-family: 'Faro Trial';
  src: local('Faro Trial'),
    url('./../../fonts/FaroTrial-BoldLucky.woff2') format('woff2'),
    url('./../../fonts/FaroTrial-BoldLucky.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Faro Trial';
  src: url('./../../fonts/FaroTrial-DisplayLucky.woff2') format('woff2'),
    url('./../../fonts/FaroTrial-DisplayLucky.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Faro Trial';
  src: url('./../../fonts/FaroTrial-RegularLucky.woff2') format('woff2'),
    url('./../../fonts/FaroTrial-RegularLucky.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
