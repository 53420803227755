@import '../../assets/sass/utilities/variables';
@import '../../assets/sass/utilities/mixins';

.profile {
  position: fixed;
  right: 0;
  top: 79px;
  bottom: 0;
  z-index: 11;
  transform: translateX(-100%);
  padding: 50px 0 0;
  width: 100%;
  overflow-y: scroll;
  background-color: $c-secondary;
  @include transition(all);
  @include breakpoint($b-md) {
    top: 90px;
  }
  &.js-opened {
    transform: translateX(0);
  }
  &__form {
    margin-bottom: 40px;
  }
  &__hidden {
    display: none;
  }
  &__new-row {
    margin-top: 5px;
    @include breakpoint($b-md) {
      width: 100%;
    }
    .c-title {
      @include breakpoint($b-md) {
        padding: 0 12px;
      }
    }
    .bx--btn {
      margin-bottom: 30px;
      @include breakpoint($b-md) {
        margin: 0 0 30px 12px;
      }
    }
  }
  .l-list {
    margin-bottom: 40px;
    &__item {
      margin-bottom: 10px;
    }
  }
}
