@import '../../assets/sass/utilities/variables';
@import '../../assets/sass/utilities/mixins';

.projects {
  min-height: calc(100vh - 326px);
  h2 {
    margin-bottom: 30px;
  }
  &__tabs {
    padding-top: 110px;
    @include breakpoint($b-md) {
      padding-top: 150px;
    }
  }
  &__list {
    margin: 0 -4px;
    display: flex;
    flex-wrap: wrap;
    @include breakpoint($b-md) {
      margin: 0 -8px;
    }
  }
  &__item {
    padding: 0 4px 8px;
    width: 50%;
    @include breakpoint($b-sm) {
      width: auto;
    }
    @include breakpoint($b-md) {
      padding: 0 8px 16px;
    }
  }
}

.c-project {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 280px;
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  @include breakpoint($b-sm) {
    width: 186px;
  }
  @include breakpoint($b-md) {
    width: 240px;
    height: 360px;
    border-radius: 20px;
  }
  &__name {
    position: absolute;
    bottom: 23px;
    padding: 0 15px;
    width: 100%;
    color: $c-secondary;
    font-weight: 800;
    font-size: $f-large;
    line-height: 26px;
    @include breakpoint($b-md) {
      bottom: 30px;
      font-weight: 600;
      font-size: 36px;
      line-height: 40px;
    }
    .part-first,
    .part-second {
      display: inline-block;
    }
    .part-first {
      word-break: break-all;
    }
    .part-second {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__new {
    position: absolute;
    top: 10px;
    left: 6px;
    z-index: 1;
    padding: 0 15px;
    color: $c-primary;
    font-size: $f-small;
    line-height: 27px;
    border-radius: 5px;
    background-color: $c-secondary;
    @include breakpoint($b-md) {
      top: 15px;
      left: 15px;
    }
  }
}
