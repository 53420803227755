.container {
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  max-width: 1440px;
  @include breakpoint($b-lg) {
    padding: 0 80px;
  }
  &--flex-md {
    @include breakpoint($b-md) {
      display: flex;
    }
  }
}

.l-list {
  &--flex-lg {
    @include breakpoint($b-lg) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.l-btn-wrap {
  margin-top: 30px;
}

.l-form-wrap {
  @include breakpoint($b-md) {
    margin: 0 -12px;
    display: flex;
    flex-wrap: wrap;
  }
}