@import '../../assets/sass/utilities/variables';
@import '../../assets/sass/utilities/mixins';

.header {
  $self: &;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  padding: 15px 0;
  background-color: $c-secondary;
  user-select: none;
  @include breakpoint($b-md) {
    padding: 30px 0;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__nav {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 11;
    transform: translateX(100%);
    padding-top: 80px;
    text-align: center;
    display: none;
    flex-direction: column;
    align-items: center;
    width: 60%;
    background-color: $c-secondary-80;
    @include transition(all);
    @include breakpoint($b-md) {
      position: static;
      margin-left: auto;
      padding-top: 0;
      width: auto;
      transform: translateX(0);
      flex-direction: row;
    }
    &.js-opened {
      transform: translateX(0);
    }
    .bx--btn {
      margin-bottom: 20px;
      @include breakpoint($b-md) {
        margin: 0 0 0 15px;
      }
    }
  }
  &__btn-wrap {
    order: 1;
    width: 33.33%;
    text-align: right;
  }
  &__menu-wrap {
    width: 33.33%;
  }
  &__logo-wrap {
    order: 1;
  }
  &__burger {
    display: block;
  }
  &--home {
    background-color: $c-secondary-80;
    #{$self}__container {
      justify-content: space-between;
    }
    #{$self}__logo-wrap {
      order: unset;
      width: auto;
    }
    #{$self}__btn-wrap {
      display: none;
    }
    #{$self}__nav {
      display: flex;
    }
    #{$self}__burger {
      display: block;
      @include breakpoint($b-md) {
        display: none;
      }
    }
    .menu-toggle {
      top: 19px;
    }
  }
  &--project {
    background-position: center top;
    background-size: auto 300px;
    background-repeat: no-repeat;
    @include breakpoint($b-md) {
      background-size: auto 375px;
    }
    @include breakpoint(1200px) {
      background-position: center top;
      background-size: 100% auto;
    }
    .menu-toggle__bar {
      background-color: $c-secondary;
    }
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none;
  background-color: rgba(0, 0, 0, 0.6);
  &.js-opened {
    display: block;
    @include breakpoint($b-md) {
      display: none;
    }
  }
}

.menu-toggle {
  $self: &;
  position: absolute;
  top: 27px;
  right: 0;
  z-index: 100;
  width: 40px;
  height: 25px;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
  &__bar {
    margin: 4px 0;
    display: block;
    width: 25px;
    height: 2px;
    background-color: $c-primary-90;
    @include transition(all);
  }
  &.js-opened {
    #{$self}__bar--1 {
      transform: rotate(45deg) translate3d(5px, 3px, 0);
    }
    #{$self}__bar--2 {
      margin-left: 28px;
      opacity: 0;
    }
    #{$self}__bar--3 {
      transform: rotate(-45deg) translate3d(5px, -3px, 0);
    }
  }
  &--arrow {
    #{$self}__bar {
      width: 15px;
    }
    #{$self}__bar--1 {
      transform: rotate(-45deg) translate3d(-2px, 0, 0);
    }
    #{$self}__bar--3 {
      transform: rotate(45deg) translate3d(-2px, 0, 0);
    }
    &.js-opened {
      #{$self}__bar {
        width: 15px;
      }
      #{$self}__bar--1 {
        transform: rotate(-45deg) translate3d(-2px, 0, 0);
      }
      #{$self}__bar--3 {
        transform: rotate(45deg) translate3d(-2px, 0, 0);
      }
    }
  }
  &--simple {
    position: static;
    height: auto;
    width: 25px;
    #{$self}__bar--2 {
      visibility: hidden;
    }
  }
}
