@import '../../../assets/sass/utilities/variables';
@import '../../../assets/sass/utilities/mixins';

.socials {
  margin-bottom: 40px;
  &__label {
    margin-bottom: 8px;
    display: block;
    color: $c-secondary-80;
    font-size: $f-small;
    line-height: 16px;
  }
  &__list {
    display: flex;
  }
  &__item {
    margin-right: 15px;
  }
}
