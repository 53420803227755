@import '../../../assets/sass/utilities/variables';
@import '../../../assets/sass/utilities/mixins';

.member {
  display: flex;
  &--f-center {
    align-items: center;
  }
  &__photo {
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 10px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__info {
    margin: 0 15px;
  }
  &__contact {
    margin-left: auto;
  }
  &--hide {
    display: none;
  }
  &--show-md {
    @include breakpoint($b-md) {
      display: flex;
    }
  }
  &--hide-md {
    @include breakpoint($b-md) {
      display: none;
    }
  }
}
