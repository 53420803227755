/* Fonts */
$font-primary: 'Faro Trial', Arial, sans-serif;

/* Font sizes */
$f-huge: 40px;
$f-biggest: 32px;
$f-big: 26px;
$f-large: 24px;
$f-medium: 20px;
$f-main: 17px;
$f-small: 15px;

/* Colors */
$c-primary: #000000;
$c-primary-90: #161616;
$c-primary-80: #262626;
$c-primary-50: #525252;
$c-secondary: #fff;
$c-secondary-90: #f7f6fb;
$c-secondary-80: #f4f4f4;
$c-secondary-50: #c6c6c6;
$c-secondary-30: #a8a8a8;
$c-error: #ff1313;

/* Breakpoints */
$b-sm: 480px;
$b-md: 672px;
$b-ipad: 768px;
$b-xmd: 992px;
$b-lg: 1056px;
$b-xlg: 1312px;
$b-max: 1584px;
