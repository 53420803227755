@import '../../../assets/sass/utilities/variables';
@import '../../../assets/sass/utilities/mixins';

.image-upload {
  .bx--btn {
    margin-bottom: 25px;
  }
  &__photo-wrap {
    width: 186px;
    height: 186px;
    overflow: hidden;
    border-radius: 10px;
  }
  &__photo {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__uploader {
    @include breakpoint($b-md) {
      margin: 0 -12px;
    }
    .bx--btn {
      margin-bottom: 0;
    }
  }
  .bx--file--label,
  .bx--file--label-description {
    display: none;
  }
}
