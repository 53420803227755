@import '../../../../assets/sass/utilities/variables';
@import '../../../../assets/sass/utilities/mixins';

.c-manage-assets {
  &__heading {
    display: flex;
    justify-content: space-between;
    .c-title {
      margin-bottom: 0;
    }
  }
  .l-list {
    @include breakpoint($b-lg) {
      margin: 0 -4px;
    }
  }
  .l-list__item {
    margin-bottom: 8px;
    @include breakpoint($b-lg) {
      padding: 0 4px;
      width: 50%;
    }
  }
  .bx {
    &--modal-header {
      margin-bottom: 40px;
      padding-right: 15px;
      .bx--btn {
        margin: 0;
        min-width: auto;
      }
    }
    &--modal-container {
      left: 0;
      .bx--modal-header {
        @include breakpoint($b-md) {
          padding-right: 40px;
        }
      }
    }
    &--modal-close {
      display: none;
    }
    &--btn {
      min-width: 0;
      width: 48%;
      @include breakpoint($b-sm) {
        margin: 0 15px 10px 0;
        min-width: 188px;
        width: auto;
      }
    }
  }
  .l-btn-wrap {
    display: flex;
    justify-content: space-between;
    @include breakpoint($b-sm) {
      display: block;
    }
  }
}