@import '../../../assets/sass/utilities/variables';
@import '../../../assets/sass/utilities/mixins';

.c-asset {
  padding: 13px 15px;
  display: flex;
  justify-content: space-between;
  background-color: $c-secondary-80;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  &:hover {
    @include breakpoint($b-md) {
      background-color: $c-secondary-50;
    }
  }
  &__name {
    color: $c-primary;
    font-size: $f-main;
  }
  &__icon {
    color: $c-primary;
    svg {
      margin-top: 4px;
      width: 17px;
      height: 17px;
    }
  }
}
