@import '../../assets/sass/utilities/variables';
@import '../../assets/sass/utilities/mixins';

.section {
  background: $c-secondary;
  &--hero {
    background: $c-secondary-90;
    background: linear-gradient(to bottom, #f7f6fb 0%, #e6e5ea 100%);
  }
  &--crop {
    overflow: hidden;
  }
  &--dark {
    background-color: $c-primary-90;
  }
}
.title {
  margin-bottom: 24px;
  font-size: $f-biggest;
  line-height: 40px;
  @include breakpoint($b-md) {
    font-size: $f-big;
    line-height: 34px;
  }
  @include breakpoint($b-xmd) {
    font-size: $f-biggest;
    line-height: 40px;
  }
  &--main {
    margin-bottom: 48px;
  }
}

.text {
  margin-bottom: 25px;
  font-size: $f-main;
  line-height: 28px;

  &--small {
    font-size: $f-small;
    line-height: 24px;
  }
}

.hero {
  padding-top: 80px;
  @include breakpoint($b-md) {
    padding: 109px 0 0;
    display: flex;
  }
  &__visual {
    overflow: hidden;
    text-align: center;
    @include breakpoint($b-md) {
      margin-top: auto;
      order: 2;
      width: 60%;
    }
  }
  &__info {
    margin: 0 -15px;
    padding: 40px 15px 60px;
    background: $c-secondary-90;
    @include breakpoint($b-md) {
      margin: 0;
      padding: 20px 5% 20px 0;
      order: 1;
      width: 40%;
      max-width: 410px;
      background-color: transparent;
    }
    @include breakpoint($b-lg) {
      padding-top: 65px;
      padding-bottom: 90px;
    }
    @include breakpoint($b-xlg) {
      padding-right: 0;
    }
  }
  &__img {
    margin-bottom: -3px;
    width: 100%;
    @include breakpoint($b-md) {
      max-width: 600px;
    }
  }
  &__caption {
    margin-bottom: 20px;
    display: block;
    color: $c-primary-50;
    font-size: $f-small;
    line-height: 16px;
  }
  .btn {
    margin-bottom: 15px;
  }
}

.article {
  $self: &;
  padding: 80px 0 60px;
  @include breakpoint($b-md) {
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &--dark {
    .title {
      color: $c-secondary;
    }
    .text {
      color: $c-secondary;
    }
  }
  &--1 {
    @include breakpoint($b-md) {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
    }
    #{$self}__visual {
      margin: 0 auto 50px;
      text-align: center;
      width: 90%;
      overflow: hidden;
      @include breakpoint($b-md) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 60%;
        z-index: 1;
        margin: 0;
        text-align: left;
        width: 40%;
        display: flex;
        align-items: center;
      }
      @include breakpoint($b-xmd) {
        top: auto;
        bottom: -25%;
        display: block;
      }
      @include breakpoint($b-xlg) {
        padding-left: 0;
      }
    }
    #{$self}__img {
      max-width: 320px;
      margin-bottom: -55%;
      @include breakpoint($b-md) {
        margin-bottom: 0;
        max-width: 240px;
      }
      @include breakpoint($b-xmd) {
        max-width: 365px;
      }
    }
    #{$self}__info {
      @include breakpoint($b-md) {
        margin-left: 45%;
        width: 55%;
      }
      &--1 {
        margin-bottom: 35px;
        @include breakpoint($b-xmd) {
          margin-bottom: 80px;
        }
      }
    }
    .text {
      margin-bottom: 15px;
      @include breakpoint($b-md) {
        margin-bottom: 24px;
      }
    }
  }
  &--2 {
    @include breakpoint($b-md) {
      justify-content: flex-start;
    }
    #{$self}__visual {
      @include breakpoint($b-md) {
        padding-left: 5%;
      }
      @include breakpoint($b-lg) {
        padding-left: 80px;
      }
    }
    #{$self}__img {
      height: 139px;
      width: auto;
      @include breakpoint($b-md) {
        height: 222px;
      }
    }
    .text {
      margin-bottom: 50px;
      @include breakpoint($b-md) {
        margin-bottom: 0;
      }
    }
  }
  &--3 {
    padding: 20px 0 0;
    @include breakpoint($b-md) {
      padding: 80px 0;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    #{$self}__visual {
      margin: 0 -15px;
      @include breakpoint($b-md) {
        margin: 0;
        padding-right: 5%;
        text-align: right;
      }
      @include breakpoint($b-lg) {
        padding-right: 80px;
      }
    }
    #{$self}__img {
      margin-bottom: -3px;
      @include breakpoint($b-md) {
        margin-bottom: 0;
        margin-left: 100%;
        transform: translateX(-865px);
        height: 450px;
        width: auto;
      }
    }
    .text {
      margin-bottom: 50px;
      @include breakpoint($b-md) {
        margin-bottom: 0;
      }
    }
  }
  &--4 {
    padding-bottom: 40px;
    @include breakpoint($b-md) {
      justify-content: flex-start;
    }
    #{$self}__visual {
      @include breakpoint($b-md) {
        padding-left: 5%;
      }
      @include breakpoint($b-lg) {
        padding-left: 80px;
      }
      @include breakpoint($b-xlg) {
        padding-left: 138px;
      }
    }
    #{$self}__img {
      width: auto;
      height: 270px;
      @include breakpoint($b-md) {
        height: 360px;
      }
    }
    #{$self}__info {
      margin-bottom: 45px;
    }
  }
  &__info {
    @include breakpoint($b-md) {
      width: 40%;
      max-width: 470px;
    }
    &--1 {
      @include breakpoint($b-md) {
        margin-bottom: 50px;
      }
    }
  }
  &__visual {
    @include breakpoint($b-md) {
      width: 60%;
    }
  }
  &__img {
    width: 100%;
    &--show-md {
      display: none;
      @include breakpoint($b-md) {
        display: block;
      }
    }
    &--hide-md {
      display: block;
      @include breakpoint($b-md) {
        display: none;
      }
    }
  }
  .title {
    @include breakpoint($b-md) {
      margin-bottom: 16px;
    }
  }
}
.error-notifications {
  position: fixed;
  top: 100px;
  right: 0;
  left: 0;
  z-index: 9999;
  margin: 0 auto;
  .bx--inline-notification {
    margin: 0 auto;
  }
}
